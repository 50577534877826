(function ($) {
    $.fn.scorebanner = function (opts) {
        // default configuration
        var config = $.extend({}, {
        }, opts);
        // main function
        function init(obj) {
            var pointsMax = $(obj).data('maxpoints'),
                pointsWin = (pointsMax / 2) + 0.5,
                hostScore = $(obj).data('scoreHost'),
                guestScore = $(obj).data('scoreGuest'),
                hostName = $(obj).find('.result-sides .host .name').text(),
                guestName = $(obj).find('.result-sides .guest .name').text();

            $('.result-status-towin .points').text(pointsWin);
            $('.result-bar .score.host').text(hostScore);
            $('.result-bar .score.guest').text(guestScore);
            $('.result-bar .bar.host').css('width', (hostScore / pointsMax) * 100 + '%');
            $('.result-bar .bar.guest').css('width', (guestScore / pointsMax) * 100 + '%');
            
            if (hostScore >= pointsWin) {
                $('.result-headline').addClass('show').text(hostName + ' wins');
            } else if (guestScore >= pointsWin) {
                $('.result-headline').addClass('show').text(guestName + ' wins');
            }
        };
        
        function update() {
            alert('update');
        };
        // initialize every element
        this.each(function () {
            init($(this));
        });
        return this;
    };
    // start
    $(function () {
        $('.result').scorebanner();
    });
})(jQuery);