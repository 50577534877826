var debug;

//jQuery(document).ready(function () {
document.addEventListener("turbolinks:load", function() {

    var lancs_score = $(".score.lancaster");
    var york_score = $(".score.york");

    var lancs_race = $(".bar.lancaster");
    var york_race = $(".bar.york");

    $("#load-btn").click(function (event) {
        event.preventDefault();
        load_old();
    });

    (function poll() {
        setTimeout(function () {

            $.ajax({ url: "/feed/since/" + latest_feed_id, success: function (data) {

                // console.log("livefeed.js received /feed/since/"+latest_feed_id);
                // console.log("livefeed.js data received: ");
                // console.log(JSON.stringify(data));

                if (data.length > 0) {
                    latest_feed_id = data[data.length - 1].id;
                    // console.log("livefeed.js data.length > 0 ("+data.length+"), latest_feed_id is determined as: "+latest_feed_id);
                    // Display the entry
                    jQuery.each(data, function (i) {
                        // Below is to fix obscure issue of sport id and team name appearing undefined in updates
                        // to "Live Reporting" live moments giving showing incorrect team names and no sport id for links
                        the_team_name = data[i].team.sport.title + ", " + data[i].team.title;
                        the_sport_id = data[i].team.sport.id;
                        data[i].team_name = the_team_name;
                        data[i].sport_id = the_sport_id;
                        select_partial(data[i], 'prepend');
                    });
                }
                else {
                }
            }, dataType: "json", beforeSend: function (req) {
                req.setRequestHeader("X-SILENCE-LOGGER", 'Silence');
            }
            });

            $.ajax({ url: "/score.json", success: function (data) {

                // Update Score
                lancs_score.html(data.lancs);
                york_score.html(data.york);

                var lancs_percentage = (data.lancs / total_pts) * 100;
                var york_percentage = (data.york / total_pts) * 100;

                lancs_race.css("width", lancs_percentage + "%");
                york_race.css("width", york_percentage + "%");
				
				// show headline
				if (data.lancs >= ((total_pts/2) + 0.5) ) {
					$('.result-headline').addClass('show').text('Lancaster wins');
				} else if (data.york >= ((total_pts/2) + 0.5) ) {
					$('.result-headline').addClass('show').text('York wins');
				}

                // Re-call poll() after timeout
                poll();
            }, dataType: "json", beforeSend: function (req) {
                req.setRequestHeader("X-SILENCE-LOGGER", 'Silence');
            }
            });

        }, 60 * 1000);  // ORIGINALL 20000
    })();

});

function load_old() {

    $.ajax({ url: "/feed/directlybefore/" + oldest_feed_id, success: function (data) {
        if (data.length > 0) {
            oldest_feed_id = data[data.length - 1].id;
            jQuery.each(data, function (i) {

                //DONT LOAD PAST THIS FEED ID, EXCLUDES PREVIOUS YEAR
                if (data[i].id >= 7718) {
                    select_partial(data[i], 'append');
                } else {
                    $("#load-btn").hide();
                }
            });
        }
    }});
}

function select_partial(livemoment, append) {
    var livemoment_type_name = livemoment.live_moment_type.name

    // console.log("livefeed.js select_partial received livemoment.id:"+livemoment.id);
    // console.log("livefeed.js select_partial received livemoment.sport_id:"+livemoment.sport_id);
    // console.log("livefeed.js select_partial received livemoment.text:"+livemoment.text);
    // console.log("livefeed.js select_partial received livemoment.team_name:"+livemoment.team_name);

    switch (livemoment_type_name) {
        case "General Commentary" :
            display_text(livemoment, append);
            break;
        case "Significant Moment" :
            display_text(livemoment, append);
            break;
        case "Goal" :
            display_sport_text_score(livemoment, append);
            break;
        case "Full Time" :
            display_sport_text_score(livemoment, append);
            break;
        case "Half Time" :
            display_sport_text_score(livemoment, append);
            break;
        case "1st Quarter" :
            display_sport_text_score(livemoment, append);
            break;
        case "3rd Quarter" :
            display_sport_text_score(livemoment, append);
            break;
        case "Kick Off" :
            display_sport_text(livemoment, append);
            break;
        case "Quote" :
            display_quote(livemoment, append);
            break;
        case "Lancs Quote" :
            display_quote(livemoment, append);
            break;
        case "York Quote" :
            display_quote(livemoment, append);
            break;
        case "Image" :
            display_photo(livemoment, append);
            break;
        case "Tweet" :
            display_tweet(livemoment, append);
            break;
        case "External Link" :
            display_link(livemoment, append);
            break;
        case "Score Update" :
            display_scoreupdate(livemoment, append);
            break;
        default :
            display_text(livemoment, append);
    }

}

function created_at(post_time) {
    var created_at = new Date(post_time);
    var h = created_at.getHours();
    if (h < 10) {
        h = "0" + h.toString();
    }
    var m = created_at.getMinutes();
    if (m < 10) {
        m = "0" + m.toString();
    }
    return(h + ":" + m);
}

function display_text(livemoment, append) { //WORKING


    if (livemoment.timetable_entry_id != null) {
        if ((livemoment.score_game_lancs != null) || (livemoment.score_game_york != null)) {
            display_sport_text_score(livemoment, append);
        }
        else {
            display_sport_text(livemoment, append);
        }
    }
    else {
        //Time
        $('#text .time span').html(created_at(livemoment.created_at));
        //Text
        if (livemoment.live_moment_type.overlay_class == "significant") {
            $('#text .message .text').attr('class', 'text moment');
            $('#text .message .text').html(livemoment.text);
        }
        else {
            $('#text .message .text').attr('class', 'text');
            $('#text .message .text').html(livemoment.text);
        }

        // Append
        if (append === "append") {
            $("#text").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
        } else {
            $("#text").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
        }
    }
}


function display_sport_text(livemoment, append) { //WORKING

    // console.log("livefeed.js display_sport_text received livemoment.id:"+livemoment.id);
    // console.log("livefeed.js display_sport_text received livemoment.sport_id:"+livemoment.sport_id);
    // console.log("livefeed.js display_sport_text received livemoment.text:"+livemoment.text);
    // console.log("livefeed.js display_sport_text received livemoment.team_name:"+livemoment.team_name);

    //Time
    $('#sport-text .time span').html(created_at(livemoment.created_at));
    //Icon
    var icon = "background-position: 0 -" + ((livemoment.sport_id * 48) - 48) + "px";
    $('#sport-text .icon .sport').attr('style', icon);

    var overlay = "overlay " + livemoment.live_moment_type.overlay_class;
    $('#sport-text .icon .overlay').attr('class', overlay);

    //Sport
    var href = "http://roseslive.co.uk/sport/" + livemoment.sport_id;
    $('#sport-text .message p a').attr('href', href);
    $('#sport-text .message p a').html(livemoment.team_name);
    //Game Time
    if (livemoment.live_moment_type.name == 'Kick Off') {
        $('#sport-text .message .game-time').show();
    }
    else {
        $('#sport-text .message .game-time').hide();
    }
    //Text
    if (livemoment.live_moment_type.overlay_class == "significant") {
        $('#sport-text .message .text').attr('class', 'text moment');
        $('#sport-text .message .text').html(livemoment.text);
    }
    else {
        $('#sport-text .message .text').attr('class', 'text');
        $('#sport-text .message .text').html(livemoment.text);
    }
    // Append
    if (append === "append") {
        $("#sport-text").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#sport-text").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

function display_sport_text_score(livemoment, append) { //WORKING
    //Time
    $('#sport-text-score .time span').html(created_at(livemoment.created_at));
    //Icon
    var icon = "background-position: 0 -" + ((livemoment.sport_id * 48) - 48) + "px";
    $('#sport-text-score .icon .sport').attr('style', icon);
    var overlay = "overlay " + livemoment.live_moment_type.overlay_class;
    $('#sport-text-score .icon .overlay').attr('class', overlay);
    //Sport
    var href = "http://roseslive.co.uk/sport/" + livemoment.sport_id;
    $('#sport-text-score .message p a').attr('href', href);
    $('#sport-text-score .message p a').html(livemoment.team_name);
    //Game-Time
    if (livemoment.live_moment_type.name == 'Full Time') {
        $('#sport-text-score .message .game-time').html('FULL TIME');
    }
    else if (livemoment.live_moment_type.name == 'Half Time') {
        $('#sport-text-score .message .game-time').html('HALF TIME');
    }
    else if (livemoment.live_moment_type.name == '3rd Quarter') {
        $('#sport-text-score .message .game-time').html('3RD QUARTER');
    }
    else if (livemoment.live_moment_type.name == '1st Quarter') {
        $('#sport-text-score .message .game-time').html('1ST QUARTER');
    }
    else if (livemoment.live_moment_type.name == 'Goal') {
        $('#sport-text-score .message .game-time').html('SCORE UPDATE');
    }
    else {
        $('#sport-text-score .message .game-time').html(livemoment.text);
    }
    //Text
    if (livemoment.live_moment_type.overlay_class == "significant") {
        $('#sport-text-score .message .text').attr('class', 'text moment');
        $('#sport-text-score .message .text').html(livemoment.text);
    }
    else {
        $('#sport-text-score .message .text').attr('class', 'text');
        $('#sport-text-score .message .text').html(livemoment.text);
    }
    //Score
    if ((livemoment.score_game_lancs != null) || (livemoment.score_game_york != null)) {
        $('#sport-text-score .score-card .lancaster').html(livemoment.score_game_lancs + "<span class='side'>Lancaster</span>");
        $('#sport-text-score .score-card .york').html(livemoment.score_game_york + "<span class='side'>York</span>");
    } else {
        $('#sport-text-score .score-card').hide();
    }
    // Append
    if (append === "append") {
        $("#sport-text-score").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#sport-text-score").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

function display_quote(livemoment, append) {	//WORKING
    //Time
    $('#quote .time span').html(created_at(livemoment.created_at));
    //Lancaser or York
    $('#quote .quote').addClass(livemoment.live_moment_type.overlay_class);
    //Text
    $('#quote .quote-text p').html(livemoment.text);
    //Author
    $('#quote .quote-text cite').html(livemoment.author);
    // Append
    if (append === "append") {
        $("#quote").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#quote").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

function display_photo(livemoment, append) { //WORKING
    //Time
    $('#photo .time span').html(created_at(livemoment.created_at));
    //Lancaser or York
    var src = 'https://' + livemoment.picture_file;
    $('#photo .image img').attr('src', src);
    //Author
    $('#photo .image cite').html(livemoment.author);
    // Append
    if (append == "append") {
        $("#photo").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#photo").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

function display_tweet(livemoment, append) {  //WORKING
    //Time
    $('#tweet .time span').html(created_at(livemoment.created_at));
    //Avatar
    var src = "http://" + livemoment.picture_file;
    $('#tweet .avatar img').attr('src', src);
    //Author
    $('#tweet cite').html(livemoment.author);
    //Text
    $('#tweet .text').html(livemoment.text);
    $('#tweet .text').linkify();
    // Append
    if (append === "append") {
        $("#tweet").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#tweet").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

function display_link(livemoment, append) {	//WORKING
    //Time
    $('#link .time span').html(created_at(livemoment.created_at));
    //href
    var href = "http://" + livemoment.text
    $('#link .message a').attr('href', href);
    //title
    $('#link .message a').attr('title', livemoment.author);
    //Author
    $('#link .message a').html(livemoment.author);
    // Append
    if (append === "append") {
        $("#link").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#link").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

function display_scoreupdate(livemoment, append) { //WORKING
    //Time
    $('#overallscore .time span').html(created_at(livemoment.created_at));
    //Text
    $('#overallscore .win').html(livemoment.text);
    //score
    $('#overallscore .score-card .lancaster').html(livemoment.score_roses_lancs + "<span class='side'>Lancaster</span>");
    $('#overallscore .score-card .york').html(livemoment.score_roses_york + "<span class='side'>York</span>");
    // Append
    if (append === "append") {
        $("#overallscore").clone().hide().removeAttr("id").appendTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    } else {
        $("#overallscore").clone().hide().removeAttr("id").prependTo('.feed').effect("highlight", {color: '#BBBBBB'}, 2000);
    }
}

