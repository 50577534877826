
document.addEventListener("turbolinks:load", function() {
    //console.log("countdown loaded");
    document.querySelectorAll('.countdown-clock').forEach(function(countdown_container) {

        //console.log(countdown_container.className);
        $(countdown_container).countdown({
                date: countdown_container.dataset.endDate,
                offset: +2,
                day: 'Day',
                days: 'Days',
                hideOnComplete: true
            }
            // , function (container) {
            //     alert('Done!');
            // }
        );


    });
});


document.addEventListener("turbolinks:load", function() {
//jQuery(document).ready(function () {

    $(".fit-text").fitText(0.25);

    $('.scrollTo').on('click', function (event) {
        event.preventDefault();
        $.scrollTo($(event.target.hash), 1000, {offset: {top: -80}}, {'axis': 'y'});
    });

    var height = $(window).height() - 80 - 126 - 261 + 72;

    // Vanilla JS
    var radioStreams = document.querySelectorAll('.radio-play');
    for (var i = 0; i < radioStreams.length; i++) {
        radioStreams[i].addEventListener('click', function (e) {
            e.preventDefault();
            newwindow = window.open($(this).attr("href"), 'playerWindow', 'top=165,left=190,height=660,width=380');
        }, false);
    }




    // Video on demand and live streams
    $(".player-and-menu").each(function() {
        var $iframe = $(this).find(".player-iframe").first();
        var $title = $(this).find(".title").first();
        $(this).find(".video-play").each(function() {
            var iframeUrl = $(this).attr("data-iframe-url");
            var title = $(this).text();
            $(this).click(function(e) {
                e.preventDefault();
                $title.html(title);
                $iframe.attr("src", iframeUrl);
            });
        });
    });

});

jQuery.fn.exists = function () {
    return this.length > 0;
};