import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


import "../stylesheets/application"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

//require('jquery')
require("jquery-ui")

require('../src/public/modernizr-custom-min')
require('../src/public/livefeed')
require('../src/public/jquery.fittext')

require('../src/public/jquery.countdown_epiksel')
require('../src/public/jquery.newsticker.min')
require('../src/public/jquery.waypoints.min')
require('../src/public/jquery.scorebanner')
require('../src/public/jquery.cleanaudioplayer')

require('../src/public/public_main')

